.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.mynavbar {
  background-color: #005fc7;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.mylink{
  color: whitesmoke;
}
.mylink:hover {
  color: whitesmoke;
  font-size: larger;

}
.mylink:active {
  color: whitesmoke;
  font-size: larger;
}
.opHeader {
  font-weight: bold ;
  color: gray;
}

.mysumbadge{
  font-size: 40px;
  font-weight: bold ;
  padding: 0px;
  background-color: #f8f9fa;

}

.mycard {
  padding: 2px;
  margin-left: 2px;
}

.mycardtitle {
  margin: 0px;
  padding: 0px;
  align-self: flex-start;
  font-weight: bold ;
  font-size: x-large;
  color: whitesmoke;
  background-color: #005fc7;
}

.mycardbody {
  background-color: #F0F0F0;
}

.mybutton {
  background-color: #f8f9fa;
}

.App-link {
  color: whitesmoke;
}

.App-active-link {
  color: whitesmoke;
}

.input-group>.input-group-prepend {
  flex: 0 0 10%;
}
.input-group>.input-group-append {
  flex: 0 0 10%;
}

.MiddleInput{
  flex: 0 0 30%;
}

.header-class {
  color: whitesmoke;
  background-color: #005fc7;
}

.table-row {
  margin: 0px;
  padding: 0px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
